<template>
  <section>
    <div id="pdf-content" v-if="dataItem.length > 0">
      <section id="surat">
        <!-- <div class="row mb-2">
          <div class="col-11 offset-1">
            <div class="row" id="kop">
              <div class="col-2 bb">
                <img src="@/assets/logo/logo_surat.png" alt="" />
              </div>
              <div class="col-10 bb">
                <h3 id="judul">
                  PEMERINTAH KABUPATEN BANDUNG <br />
                  DINAS PEKERJAAN UMUM DAN TATA RUANG <br />
                  UPTD PENERANGAN JALAN UMUM <br />
                </h3>
                <p id="alamat">
                  Jl. RAYA SOREANG-BANJARAN KM. 3 SOREANG TELP. 5892773-5892580 –
                  SOREANG
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row" id="halaman">
          <div class="col">
            <p
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                overflow: hidden;
                text-decoration: underline;
              "
            >
              REKAPITULASI PEMAKAIAN BARANG PEMELIHARAAN PJU
            </p>

            <div id="content">
              <center v-if="isAssignmentLoading">
                <br />
                <br />
                <vue-loading></vue-loading>
              </center>
              <br />
              <template v-if="!isAssignmentLoading">
                <table
                  class="table table-sm table-borderless"
                  style="margin-top: -10px"
                >
                  <thead>
                    <tr class="text-center">
                      <th class="border-set" rowspan="2">NO</th>
                      <th class="border-set" rowspan="2">JENIS MATERIAL</th>
                      <th class="border-set" rowspan="2">SATUAN</th>
                      <th class="border-set" :colspan="jmlTeam">{{ getMonthName(params.bulan) }} {{ params.tahun }}</th>
                      <th class="border-set" rowspan="2">JUMLAH</th>
                    </tr>
                    <tr>
                      <th v-for="(team, index) in teams" :key="index" class="border-set">{{ team.name }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="(item, key) in dataItem" :key="key" >
                      <td class="text-center border-set">{{ key + 1 }}.</td>
                      <td class="border-set" style="width: 50%">
                        {{ item.name }}
                      </td>
                      <td class="border-set">
                        {{ item.satuan }}
                      </td>
                      <td v-for="(team, teamIndex) in teams" :key="teamIndex" class="border-set">
                        <span v-if="item.usage_data.some(usageItem => usageItem.team_name === team.name)">{{ item.usage_data.find(usageItem => usageItem.team_name === team.name).qty_usage }}</span>
                        <span v-else>0</span>
                      </td>
                      <td class="border-set">{{ item.total_qty }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <p>Data Tidak Ditemukan</p>
    </div>
    <!-- Modal -->
    <CModal 
      size="sm"
      :title="'Pilih Format Print'"
      centered
      :show.sync="showModal"
      :closeOnBackdrop="false"
      >
        <template>
          <div class="modal-body">
            <p>Silahkan pilih format print</p>
          </div>
        </template>
        <template #footer>
          <div class="modal-footer">
            <CButton @click="exportAsPDF" class="btn btn-primary">PDF</CButton>
            <CButton @click="exportAsExcel" class="btn btn-primary">Excel</CButton>
            <CButton @click="closeModal" class="btn btn-secondary mr-3">Close</CButton>
          </div>
        </template>
      </CModal>
  </section>
</template>

<script>
export default {
  data() {
    return {
      id : null,
      dataItem: null,
      dataTeam: null,
      isAssignmentLoading: true,
      isLoading: true,
      teams : null,
      jmlTeam : null,
      params: {
        tahun: "",
        bulan: "",
      },
      params2: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      showModal: false,
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      this.$store.dispatch("report/getReportTeamItemUsage", this.params).then((resp) => {
        loading.hide();
        this.dataItem = resp.data;
        // console.log(this.dataItem);
        // setTimeout(() => {
          this.isAssignmentLoading = false;
          this.isLoading = false;
          this.showModal = true;
        // }, 2000);
        // setTimeout(function () {
        //   window.print();
        // }, 5000);
      }).catch(e => {
        this.$toast.error(e);
        loading.hide();
      });
    },
    getDataTeam() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("team/getTeam", this.params2)
        .then((resp) => {
          this.teams = resp.data.data
          this.jmlTeam = this.teams.length
          // console.log(this.jmlTeam)
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getMonthName(month) {
      const months = [
        "Januari", "Februari", "Maret", "April",
        "Mei", "Juni", "Juli", "Agustus",
        "September", "Oktober", "November", "Desember"
      ];

      return months[parseInt(month) - 1];
    },
    closeModal() {
      this.showModal = false;
      window.close();
    },
    exportAsPDF() {
      this.showModal = false;
      setTimeout(function () {
          window.print();
      }, 5000);
    },
    exportAsExcel() {
      if (!this.dataItem || this.dataItem.length === 0) {
        return;
      }

      const tahun = this.params.tahun;
      const bulan = this.getMonthName(this.params.bulan);
      const titleRow = [`REKAPITULASI PEMAKAIAN BARANG PEMELIHARAAN PJU - ${bulan} ${tahun}`];

      const headerRow = [
        'NO',
        'JENIS MATERIAL',
        'SATUAN',
        ...this.teams.map((team) => team.name),
        'JUMLAH',
      ];

      let csvContent = `${titleRow.join(',')}\n${headerRow.join(',')}\n`;

      this.dataItem.forEach((item, index) => {
        const rowData = [
          index + 1,
          item.name,
          item.satuan,
          ...this.teams.map((team) => {
            const usageData = item.usage_data.find((usageItem) => usageItem.team_name === team.name);
            return usageData ? usageData.qty_usage : 0;
          }),
          item.total_qty,
        ];

        csvContent += `${rowData.join(',')}\n`;
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', `data:text/csv;charset=utf-8,${encodedUri}`);
      link.setAttribute('download', 'RekapitulasiPemakaianBarangPemeliharaanPJU.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.showModal = false;
    },
  },
  computed: {
    computedData() {
      return this.dataItem.map((item) => {
        return {
          ...item,
        }
      });
    }
  },
  created() {
    this.params.tahun = this.$route.query.tahun;
    this.params.bulan = this.$route.query.bulan;
  },
  mounted() {
    this.getData()
    this.getDataTeam()
  },
};
</script>
  
  
<style scoped>
  body {
    font-size: 9px;
  }
  
  #judul {
    color: black;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
  }
  #alamat {
    color: black;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
  }
  #kop {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 38px;
    padding-right: 19px;
    /* padding-bottom: 28px; */
    border-bottom: 11px double black;
  }
  #halaman {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 113px;
    padding-right: 76px;
    padding-bottom: 28px;
    font-family: "Times New Roman", Times, serif;
  }
  img {
    color: black;
    height: 110px;
  }
  #content {
    color: black;
    text-align: justify;
    font-size: 20px;
  }
  .border-set {
    /* font-size: 20px; */
    border: 2px solid #000 !important;
  }
  p {
    color: black;
    margin: 5px;
  }
</style>

<style>
@media print {
  @page {
    size: landscape;
  }
}
#pdf-content {
  width: calc(29.7cm - 2cm * 2);
  /* margin: 28pt 32pt 28pt 32pt;
        word-spacing: 1pt;
        word-break: page-break; */
  color: #000 !important;
}
#content-table {
  font-size: 8pt;
  /* border-top: 1pt solid black;
  border-left: 1pt solid black; */
  border-collapse: collapse;
}
#content-table th,
#content-table td {
  /* border-right: 1px solid black;
  border-bottom: 1px solid black; */
  border: 1px solid black;
  padding-left: 3pt;
  padding-right: 3pt;
}
#footer {
  font-size: 8pt;
}
table {
  white-space: normal !important;
}
</style>